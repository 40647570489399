import { ChangeTagEvent, RoomTag } from "./model/ws-event";
// Anything exported from this file is importable by other in-browser modules.
import { BehaviorSubject, Subject } from "rxjs";
import { WebSocketTopic } from "./enum/topics";
import "./global.css";

import * as io from "socket.io-client";
import { Auth } from "./model/auth";
import {
  CommandHistoryEvent,
  RemoteCommandEvent,
  SendFileEvent,
  SendMessageEvent,
  StartRecordingEvent,
  StopRecordingEvent,
} from "./model/ws-event";

let socket;
export const messages = new Subject<SendMessageEvent>();
export const files = new Subject<SendFileEvent>();
export const recording = new Subject<StartRecordingEvent | null>();
export const commandHistory = new Subject<CommandHistoryEvent>();
export const sessionTag = new BehaviorSubject<string | null>(null);

const timeout = setInterval(() => {
  if (sessionStorage.getItem("coreAuth")) {
    initSession();
  }
}, 500);

export function initSession() {
  clearInterval(timeout);
  const savedSession = sessionStorage.getItem("coreAuth");
  const auth: Auth = JSON.parse(window.atob(savedSession)) as Auth;
  socket = io(process.env.WEBSOCKET_URL, {
    autoConnect: true,
    transports: ["websocket"],
    forceNew: false,
    query: {
      user: auth.id,
      organization: auth.organization,
    },
    upgrade: true,
    rememberUpgrade: true,
  });

  socket.on(WebSocketTopic.JOINED_ROOM, (data) => {
    console.debug(data);
  });

  socket.on(WebSocketTopic.LEFT_ROOM, (data) => {
    console.debug(data);
  });

  socket.on(WebSocketTopic.MESSAGE_RECEIVED, (data) => {
    console.debug(data);
    messages.next(data);
  });

  socket.on(WebSocketTopic.FILE_RECEIVED, (data) => {
    console.debug(data);
    files.next(data);
  });

  socket.on(WebSocketTopic.RECEIVE_REMOTE_COMMAND, (data) => {
    console.debug(data);
  });

  socket.on(WebSocketTopic.START_RECORDING_RECEIVED, (data) => {
    recording.next(data);
  });

  socket.on(WebSocketTopic.STOP_RECORDING_RECEIVED, () => {
    recording.next(null);
  });

  socket.on(WebSocketTopic.CONNECTED, (data) => {
    console.debug(data);
  });

  socket.on(WebSocketTopic.DISCONNECTED, (data) => {
    console.debug(data);
  });

  socket.on(WebSocketTopic.COMMAND_HISTORY_RECEIVED, (data) => {
    console.debug(data);
    commandHistory.next(data);
  });

  socket.on(WebSocketTopic.CHANGE_TAG_RECEIVED, (data: ChangeTagEvent) => {
    sessionTag.next(data.tag);
  });
}

/**
 *
 * @param channel channel o room a cui inviare il messaggio.
 * @param message messaggio da inviare.
 */
export function sendMessage(sendMessageEvent: SendMessageEvent): void {
  socket?.emit(WebSocketTopic.SEND_MESSAGE, sendMessageEvent);
}

export function sendFile(sendFileEvent: SendFileEvent): void {
  socket?.emit(WebSocketTopic.SEND_FILE, sendFileEvent);
}

export function sendRemoteCommandEvent(
  remoteCommandEvent: RemoteCommandEvent
): void {
  socket?.emit(WebSocketTopic.SEND_REMOTE_COMMAND, remoteCommandEvent);
}

export function startRecordingEvent(event: StartRecordingEvent): void {
  socket?.emit(WebSocketTopic.START_RECORDING, event);
}

export function stopRecordingEvent(event: StopRecordingEvent): void {
  socket?.emit(WebSocketTopic.STOP_RECORDING, event);
}

export function joinRoom(room: string): void {
  socket?.emit(WebSocketTopic.JOIN_ROOM, room, ({ tag }: RoomTag) => {
    sessionTag.next(tag);
  });
}

export function leaveRoom(room: string): void {
  socket?.emit(WebSocketTopic.LEAVE_ROOM, room);
  sessionTag.next(null);
}

export function changeTag(event: ChangeTagEvent): void {
  socket?.emit(WebSocketTopic.CHANGE_TAG, event);
}
