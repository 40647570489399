/*
 * List of topics and events of WebSocket
 */
export enum WebSocketTopic {
  ACK = "ack",

  JOIN_ROOM = "joinRoom",
  JOINED_ROOM = "joinedRoom",
  LEAVE_ROOM = "leaveRoom",
  LEFT_ROOM = "leftRoom",

  SEND_MESSAGE = "sendMessage",
  MESSAGE_RECEIVED = "receiveMessage",

  SEND_FILE = "sendFile",
  FILE_RECEIVED = "receiveFile",

  SEND_REMOTE_COMMAND = "sendRemoteCommand",
  RECEIVE_REMOTE_COMMAND = "receiveRemoteCommand",

  CONNECTED = "userConnected",
  DISCONNECTED = "userDisconnected",
  CONNECTED_CLIENTS = "connectedClientsInOrganization",
  ALL_CONNECTED_CLIENTS = "allConnectedClients",

  START_RECORDING = "startRecording",
  START_RECORDING_RECEIVED = "receiveStartRecording",

  STOP_RECORDING = "stopRecording",
  STOP_RECORDING_RECEIVED = "receiveStopRecording",

  USER_DELETED = "userDeleted",
  ME_DELETED = "meDeleted",

  COMMAND_HISTORY_RECEIVED = "commandHistoryReceived",

  CHANGE_TAG = "changeTag",
  CHANGE_TAG_RECEIVED = "changeTagReceived",
}
